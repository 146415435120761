import react, { useState, useEffect } from 'react';
import axios from 'axios';

// CONST 
import { BACK_ENDPOINT } from '../const';

// COMPONENTS
import Header from '../base/Header';

// LAYOUTS
import ProductsCarousel from '../layouts/ProductsCarousel';
import IndexContact from '../layouts/IndexContact';
import AliatesBanks from '../layouts/AliatesBanks';
import DevicesBenefits from '../layouts/DevicesBenefits';
import ObjetivesClients from '../layouts/ObjetivesClients';

export default function Index(props) {

    const [ img, setImg ] = useState([]);
    const { cities, products } = props;
    
    const getProductsPhotos = async() => {

        await axios.get(BACK_ENDPOINT + 'photos/')
        .then(res => {

            setImg(res.data);

        })
        .catch(err => {

            console.log(err)

        })

    }

    useEffect(() => {

        // SCROLL TOP
        window.scroll(0, 0);

        getProductsPhotos();

    }, [])

    return(

        <div>
            <Header />
            <ProductsCarousel photos={ img } />
            <IndexContact cities={ cities } products={ products } />
            <div className='gray-gradient'>
                <AliatesBanks />
                <DevicesBenefits />
                <ObjetivesClients />
            </div>
        </div>

    )

}
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// IMAGES
import Logo from '../assets/media/base/w-logo.png';

// ICONS
import { faLocationDot, faHeadset } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebookF, faTiktok, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import threads from "../assets/media/base/threads-icon.webp";

export default function Footer(props) {

    function toUpperCase(str) {
        return str.toUpperCase();
    }

    return(

        <footer className='border-2-top-red bg-blue px-4 py-5'>
            <div className='d-flex flex-wrap justify-content-between'>
                <div className='col-12 col-lg-4 px-4'>
                    <img src={ Logo } alt='Logo BestPay en blanco' className='d-block mx-auto' />
                    <div className='mt-3'>
                        <ul className='list-unstyled'>
                            <li className='d-flex align-items-center'>
                                <FontAwesomeIcon icon={ faLocationDot } style={{ 'fontSize': '25px' }} />
                                <span className='ps-3'>
                                    <strong>Dirección:</strong> Av. Francisco de Miranda, Torre KPMG, piso 1 Oficina 101, Municipio Chacao (a media cuadra de la tienda IVOO). Caracas - Venezuela.
                                </span>
                            </li>
                            <li className='mt-3 d-flex align-items-center'>
                                <FontAwesomeIcon icon={ faHeadset } style={{ 'fontSize': '25px' }} />
                                <span className='ps-3'>
                                    0212-3070200
                                </span>
                            </li>
                            <li className='mt-3 d-flex align-items-center'>
                                <a className='text-decoration-none d-flex align-items-center text-white' href="https://wa.me/584142238845" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={ faWhatsapp } style={{ 'fontSize': '25px' }} />
                                    <span className='ps-3'>
                                        0414-2238845
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='col-12 col-lg-8 d-flex flex-wrap mt-4 mt-lg-0'>
                    <div className="col-12 col-md-4">
                        <h5 className='text-center'>
                            PRODUCTOS
                        </h5>
                        <ul className="list-unstyled my-5 ps-5">
                            {
                                props.products.map(p => <li><Link className='text-white text-decoration-none' to={'/productos/adquirir/' + p.id}>{ toUpperCase(p.name) }</Link></li>)
                            }
                        </ul>
                    </div>
                    <div className="col-12 col-md-4">
                        <h5 className='text-center'>
                            EMPRESA
                        </h5>
                        <ul className="list-unstyled my-5 ps-5">
                            <li>
                                <Link to='/recaudos' className='text-white text-decoration-none'>
                                    RECAUDOS
                                </Link>
                            </li>
                            <li>
                                <Link to='/bancos-aliados' className='text-white text-decoration-none'>
                                    BANCOS ALIADOS
                                </Link>
                            </li>
                            <li>
                                <Link to='/empresa' className='text-white text-decoration-none'>
                                    NOSOTROS
                                </Link>
                            </li>
                            <li>
                                <Link to='/aliados-comerciales' className='text-white text-decoration-none'>
                                    ALIADOS COMERCIALES
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-4">
                        <h5 className='text-center'>
                            CONTÁCTANOS
                        </h5>
                        <ul className="list-unstyled my-5 ps-5">
                            <li className='d-flex align-items-center'>
                                <a href='https://instagram.com/bestpayvzla' className='text-white text-decoration-none'  target='_blank' rel='noreferrer'>
                                    <FontAwesomeIcon icon={ faInstagram } width={ 20 } />
                                    <span className='ps-3'>
                                        @bestpayvzla
                                    </span>
                                </a>
                            </li>
                            <li className='d-flex align-items-center'>
                                <a href='https://www.facebook.com/Bestpayvzlaa' className='text-white text-decoration-none' target='_blank' rel='noreferrer'>
                                    <FontAwesomeIcon icon={ faFacebookF } width={ 20 } />
                                    <span className='ps-3'>
                                        bestpay vzla
                                    </span>
                                </a>
                            </li>
                            <li className='d-flex align-items-center'>
                                <a href='https://tiktok.com/@bestpayvzla' className='text-white text-decoration-none' target='_blank' rel='noreferrer'>
                                    <FontAwesomeIcon icon={ faTiktok } width={ 20 } />
                                    <span className='ps-3'>
                                        Bestpayvzla
                                    </span>
                                </a>
                            </li>
                            <li className='d-flex align-items-center'>
                                <a href='https://twitter.com/bestpayvzla' className='text-white text-decoration-none' target='_blank' rel='noreferrer'>
                                    <FontAwesomeIcon icon={ faTwitter } width={ 20 } />
                                    <span className='ps-3'>
                                        Bestpayvzla
                                    </span>
                                </a>
                            </li>
                            <li className='d-flex align-items-center'>
                                <a href='https://www.threads.net/@bestpayvzla' className='text-white text-decoration-none' target='_blank' rel='noreferrer'>
                                    <img src={ threads } alt="Threads icon on Webp" width={ 20 }  />
                                    <span className='ps-3'>
                                        Bestpayvzla
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='col-12 text-center'>
                    Desarrollado por &copy; 2023 <a className='text-white' href='https://www.instagram.com/grapps.dev/' rel='noreferrer' target='_blank'>GRAPPS</a>
                </div>
            </div>
        </footer>

    )
    
}
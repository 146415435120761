import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from 'jquery';

// CONST
import { BACK_ENDPOINT } from "../const";

// ICONS
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function Buy() {

    const [ product, setProduct ] = useState([]);
    const [ productName, setProductName ] = useState("");
    const [ data, setData ] = useState({});
    const [ send, setSend ] = useState("");
    const [ btnText, setBtnText ] = useState("Enviar");

    // GET URL PARAM
    const { id } = useParams();

    const handleSubmit = async(e) => {

        e.preventDefault();
        $('.spinner-border').removeClass('d-none');
        setBtnText('Enviando...');
        let d = {

            id_product: id,
            product: productName,
            name: data.name,
            phone: data.phone,
            email: data.email,
            content: data.content

        }

        await axios.post(BACK_ENDPOINT + 'buy/', d)
        .then(res => {

            setSend("Correo electrónico enviado correctamente");
            setBtnText('Enviar');
            $('.spinner-border').addClass('d-none')
            $('#result').css('background-color', 'green');
            $('#result').css('color', 'white');
            setTimeout(() => {

                setSend("");

            }, 4000)
            e.target.reset();


        })
        .catch(err => {

            console.log(err);

        })

    }

    const handleChange = (e) => {

        setData({

            ...data,
            [e.target.id ]: e.target.value

        })

    }

    const getProduct = async() => {

        await axios.get(BACK_ENDPOINT + 'products/index.php?id=' + id)
        .then(res => {

            console.log(res.data)
            setProductName(res.data.product.name)
            setProduct(res.data)

        })
        .catch(err => {

            console.log(err)

        })

    }

    useEffect(() => {

        window.scroll(0, 10);
        getProduct();

    }, [  ])

    function toUpperCase(str) {
        return str.toUpperCase();
    }

    return(

        <div>
            <div className="pt-5 pb-3 mt-5">
                <h1 className="text-center text-blue">ADQUIRIR PRODUCTO</h1><br/>
            </div>
            <div className="col-12 ps-5">
                <Link className="text-decoration-none text-blue bg-white px-3 py-2 border-45" to='/productos'>
                    <FontAwesomeIcon icon={ faArrowLeft } className="pe-2" />
                    Volver
                </Link>
            </div>
            <div className="d-flex col-12 px-5 pt-4 pb-5 flex-column-reverse flex-lg-row align-items-center">
                <div className="col-12 col-lg-6 pb-3">
                    <div className="col-12 d-block px-3 pb-2 text-blue">
                        {

                            Object.keys(product).length > 0 ? 

                                <h4>
                                    Está a punto de adquirir un { toUpperCase(product.product.name) }
                                </h4>
                            
                            :

                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Cargando...</span>
                                </div>


                        }
                        <p style={{ 'textIndent': '1rem' }}>
                            Llene el siguiente formulario con sus datos de contacto y un agente de nuestro equipo de ventas se comunicará con usted.
                        </p>
                    </div>
                    <div className='d-block mb-3 px-4 rounded-pill' id='result'>
                        <span>
                            {
                                send
                            }
                        </span>
                    </div>
                    <form onSubmit={ handleSubmit }>
                        <div className="col-12">
                            <div className="d-flex mb-3 align-items-center flex-column flex-md-row">
                                <div className="col-12 col-md-6 px-2">
                                    <input type="text" className="border-45 bg-transparent border-blue border-2 py-1 px-3 w-100 mb-3 mb-md-0" placeholder="Nombre y Apellido *" id='name' onChange={ handleChange } />
                                </div>
                                <div className="col-12 col-md-6 px-2">
                                    <input type='email' className="border-45 bg-transparent border-blue border-2 py-1 px-3 w-100" placeholder="Correo Electrónico *" id='email' onChange={ handleChange } required />
                                </div>
                            </div>
                            <div className="d-flex mb-3 align-items-center flex-column flex-md-row">
                                <div className="col-12 col-md-6 px-2">
                                    <input type="text" className="border-45 bg-transparent border-blue border-2 py-1 px-3 w-100 mb-3 mb-md-0" placeholder="Teléfono *" id='phone' onChange={ handleChange } required />
                                </div>
                            </div>
                            <div className="d-flex align-items-center px-2 mb-3 flex-column flex-lg-row">
                                <textarea className="border-45 bg-transparent border-blue border-2 p-3 w-100 mb-3 mb-lg-0" rows={ 6 } placeholder="Escriba su mensaje..." id='content' onChange={ handleChange } required>
                                </textarea>
                            </div>
                            <div>
                                <button type="submit" className="bg-transparent border-2 border-blue border-45 px-4 text-blue py-1 d-flex align-items-center">
                                    { btnText }
                                    <div className="spinner-border ms-2 d-none" role="status">
                                        <span className="visually-hidden">Cargando...</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-12 col-lg-6 py-3 px-5">
                    {

                        Object.keys(product).length > 0 ? 

                            <div className="text-center text-blue">
                                <div className="text-center">
                                    <h4 className="pb-3">
                                        { toUpperCase(product.product.name) }
                                    </h4>
                                    <img src={ BACK_ENDPOINT + 'media/products/' + product.product.img } className="index-contact-form-img" />
                                </div>
                            </div>

                        :
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Cargando...</span>
                            </div>

                    }
                </div>
            </div>
        </div>

    )

}
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

// CONST
import { BACK_ENDPOINT } from '../const.js';

export default function Banks() {

    const [ banks, setBanks ] = useState([]);

    const getBanks = async() => {

        await axios.get(BACK_ENDPOINT + 'banks/')
        .then(res => {

            setBanks(res.data)

        })
        .catch(err => {

            console.log(err)

        })

    }

    useEffect(() => {

        // SCROLL TOP
        window.scroll(0, 10);
        getBanks();

    }, [])

    return(

        <div>
            <div className="pt-5 mt-5">
                <h1 className="text-center text-blue">BANCOS ALIADOS</h1><br/>
            </div>
            <div className='col-12 text-center px-4 pb-5 mb-3' style={{ 'borderBottom': '4px solid #D31E23' }}>
                <Link type='button' className="text-decoration-none border-red border-45 border-2 py-2 px-4 text-blue">
                    Solicitar
                </Link>
            </div>
            <div className='col-12 p-5'>
                {

                    banks.map(bank => 
                 
                        <div className='d-flex flex-wrap col-12 mb-5'>
                            <div className='col-12 col-lg-3'>
                                <img className='d-block mx-auto' src={ BACK_ENDPOINT + 'media/banks/' + bank.img + '.webp' } width={200} />
                            </div>
                            <div className='d-flex flex-wrap col-12 col-lg-9'>
                                {

                                    bank.account === "3" ? 

                                    <div className='d-flex flex-wrap col-12'>
                                        <div className='col-12 col-lg-6  border-end-lg border-red pe-3'>
                                            <div>
                                                <h3 className='text-blue'>
                                                    APERTURA CUENTA JURÍDICA O FIRMA PERSONAL
                                                </h3>
                                            </div>
                                            <ul>
                                                {
                                                    bank.req.map(req => 
                                                    
                                                        req.id_account_type === "2" ?

                                                            <li className='text-blue'>
                                                                {
                                                                    req.content
                                                                }
                                                            </li>
                                                        
                                                        : 

                                                            ""
                                                        
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        <div className='col-12 col-lg-6 ps-4'>
                                           <div>
                                                <h3 className='text-blue'>
                                                    APERTURA CUENTA PERSONA NATURAL 
                                                </h3>
                                           </div>
                                           <ul>
                                                {
                                                    bank.req.map(req => 
                                                    
                                                        req.id_account_type === "1" ?

                                                            <li className='text-blue'>
                                                                {
                                                                    req.content
                                                                }
                                                            </li>
                                                        
                                                        : 

                                                            ""
                                                        
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    :

                                    bank.account === "2" ?

                                    <div className='col-12'>
                                        <div>
                                            <h3 className='text-blue'>
                                                APERTURA CUENTA JURÍDICA O FIRMA PERSONAL
                                            </h3>
                                        </div>
                                        <ul>
                                            {
                                                bank.req.map(req => 
                                                
                                                    req.id_account_type === "2" ?

                                                        <li className='text-blue'>
                                                            {
                                                                req.content
                                                            }
                                                        </li>
                                                    
                                                    : 

                                                        ""
                                                    
                                                )
                                            }
                                        </ul>
                                    </div>

                                    :

                                    bank.account === "1" ? 

                                    <div className='col-12'>
                                        <div>
                                        <h3 className='text-blue'>
                                                APERTURA CUENTA PERSONA NATURAL 
                                            </h3>
                                        </div>
                                    </div>

                                    :

                                        ""

                                }
                            </div>
                        </div>
                    
                    )

                }
            </div>
        </div>

    )

}
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';

// CONST
import { BACK_ENDPOINT } from '../const';

// ICONS
import { faInstagram, faTwitter, faFacebook, faTiktok } from '@fortawesome/free-brands-svg-icons';

export default function Contact(props) {

    const [ data, setData ] = useState({});
    const [ send, setSend ] = useState("");  
    const [ btnText, setBtnText ] = useState("Enviar");

    const { cities, products } = props;

    const handleSubmit = async(e) => {

        e.preventDefault();
        $('.spinner-border').removeClass('d-none');
        setBtnText('Enviando...');
        await axios.post(BACK_ENDPOINT + 'mails/', data)
        .then(res => {

            setSend("Correo electrónico enviado correctamente");
            setBtnText('Enviar');
            $('.spinner-border').addClass('d-none')
            $('#result').css('background-color', 'green');
            $('#result').css('color', 'white');
            setTimeout(() => {

                setSend("");

            }, 4000)
            e.target.reset();


        })
        .catch(err => {

            console.log(err);

        })

    }

    const handleChange = (e) => {

        setData({

            ...data,
            [e.target.id ]: e.target.value

        })

    }

    useEffect(() => {

        // SCROLL TOP
        window.scroll(0, 10);

    }, [])

    function toUpperCase(str) {
        return str.toUpperCase();
    }

    return(

        <div className="recurs-contact-form pt-5">
            <div className="d-flex col-12 p-5 flex-column-reverse flex-lg-row align-items-center">
                <div className="col-12 col-lg-6 py-3">
                    <div className='d-block mb-3 px-4 rounded-pill' id='result'>
                        <span>
                            {
                                send
                            }
                        </span>
                    </div>
                    <form onSubmit={ handleSubmit }>
                        <div className="col-12">
                            <div className="d-flex mb-3 align-items-center flex-column flex-md-row">
                                <div className="col-12 col-md-6 px-2">
                                    <input type="text" className="border-45 bg-transparent border-white border-2 py-1 px-3 w-100 mb-3 mb-md-0" placeholder="Nombre y Apellido *" id='name' onChange={ handleChange } />
                                </div>
                                <div className="col-12 col-md-6 px-2">
                                    <input type='email' className="border-45 bg-transparent border-white border-2 py-1 px-3 w-100" placeholder="Correo Electrónico *" id='email' onChange={ handleChange } required />
                                </div>
                            </div>
                            <div className="d-flex mb-3 align-items-center flex-column flex-md-row">
                                <div className="col-12 col-md-6 px-2">
                                    <input type="text" className="border-45 bg-transparent border-white border-2 py-1 px-3 w-100 mb-3 mb-md-0" placeholder="Teléfono *" id='cell' onChange={ handleChange } required />
                                </div>
                                <div className="col-12 col-md-6 px-2">
                                    <select className="border-45 bg-transparent border-white border-2 py-2 px-3 w-100" id='product' onChange={ handleChange } required>
                                        <option>
                                            -- Selecciona tu producto --
                                        </option>
                                        {

                                            products.map(p => 

                                                <option key={ p.id }>
                                                    { toUpperCase(p.name) }
                                                </option>

                                            )

                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-3 flex-column flex-md-row">
                                <div className="col-12 col-md-6 px-2">
                                    <select className="border-45 bg-transparent border-white border-2 py-2 px-3 w-100 mb-3 mb-md-0" id='city' onChange={ handleChange } required>
                                        <option>
                                            -- Ciudad --
                                        </option>
                                        {

                                            cities.map(c => 

                                                <option key={ c.id }>
                                                    { c.city }
                                                </option>

                                            )

                                        }
                                    </select>
                                </div>
                                <div className="col-12 col-md-6 px-2">
                                    <span className="text-white d-block pb-2">
                                        ¿Posee número de afiliado?
                                    </span>
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center pe-2">
                                            <label htmlFor="yes">
                                                Sí
                                            </label>
                                            <input type="radio" className="border-45 bg-transparent border-white border-2 py-1 mx-3 w-100" value="yes" id='affiliate' onChange={ handleChange } required />
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <label htmlFor="yes">
                                                No
                                            </label>
                                            <input type="radio" className="border-45 bg-transparent border-white border-2 py-1 mx-3 w-100" value="no" id='affiliate' onChange={ handleChange } required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center px-2 mb-3 flex-column flex-lg-row">
                                <textarea className="border-45 bg-transparent border-white border-2 p-3 w-100 mb-3 mb-lg-0" rows={ 6 } placeholder="Escriba su mensaje..." id='content' onChange={ handleChange } required>
                                </textarea>
                            </div>
                            <div>
                                <button type="submit" className="bg-transparent border-2 border-white border-45 px-4 text-white py-1 d-flex align-items-center">
                                    { btnText }
                                    <div className="spinner-border ms-2 d-none" role="status">
                                        <span className="visually-hidden">Cargando...</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="py-3 col-12 col-lg-6 px-5 text-center">
                    <div className="text-center text-white">
                        <h2 style={{ 'fontWeight': 'bolder' }}>
                            CONTÁCTANOS
                        </h2>
                        <div className='d-flex flex-wrap mt-5 justify-content-around'>
                            <a href='https://instagram.com/bestpayvzla' className='text-white text-decoration-none'  target='_blank' rel='noreferrer'>
                                <FontAwesomeIcon icon={ faInstagram } style={{ 'fontSize': '2rem' }} />
                            </a>
                            <a href='https://www.facebook.com/Bestpayvzlaa' className='text-white text-decoration-none' target='_blank' rel='noreferrer'>
                                <FontAwesomeIcon icon={ faFacebook } style={{ 'fontSize': '2rem' }} />
                            </a>
                            <a href='https://tiktok.com/@bestpayvzla' className='text-white text-decoration-none' target='_blank' rel='noreferrer'>
                                <FontAwesomeIcon icon={ faTiktok } style={{ 'fontSize': '2rem' }} />
                            </a>
                            <a href='https://twitter.com/bestpayvzla' className='text-white text-decoration-none' target='_blank' rel='noreferrer'>
                                <FontAwesomeIcon icon={ faTwitter } style={{ 'fontSize': '2rem' }} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}
import React from "react";
import $ from 'jquery';

export default function TrackingPopup(props) {

    function close() {

        $('.modal').hide();

    }

    return(

        <div className="modal" tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header justify-content-center">
                        <h5 className="modal-title text-blue">
                            STATUS DEL EQUIPO
                        </h5>
                    </div>
                    <div className="modal-body">
                        <div className="table-responsive">
                            <table className="col-12">
                                <thead>
                                    <tr>
                                        <th>
                                            N° orden
                                        </th>
                                        <th>
                                            Equipo
                                        </th>
                                        <th>
                                            Serial
                                        </th>
                                        <th>
                                            Estado Tracking
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                        {
                                            props.tracking.map(t => 
                                            
                                                <tr className="">
                                                    <td>
                                                        { t.order_num }
                                                    </td>
                                                    <td>
                                                        { t.article }
                                                    </td>
                                                    <td>
                                                        { t.serial }
                                                    </td>
                                                    <td>
                                                        { t.state }
                                                    </td>
                                                </tr>
                                                
                                            )
                                        }
                                </tbody>
                            </table>
                        </div>
                        <div className="col-12 mt-4 text-end">
                            <button className="bg-blue border-2 border-white border-45 px-4 text-white py-1" onClick={ close }>
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import $ from 'jquery';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

// IMAGES
import Client1 from '../assets/media/base/client1.png';
import Client2 from '../assets/media/base/client2.png';
import Client3 from '../assets/media/base/client3.png';
import Client4 from '../assets/media/base/client4.png';

export default function ObjetivesClients() {

    const nextSlide = () => {}

    const prevSlide = () => {}

    return(

        <div className="py-5 position-relative">
            <div className='text-center'>
                <h2 className='text-blue'>
                    ¿A QUIÉN VAN DIRIGIDOS NUESTROS PRODUCTOS?
                </h2>
            </div>
            <div className="carousel carousel-clients pt-5">
                <div id='objetivesSlider'>
                    <div>
                        <img src={ Client1 } alt="" />
                    </div>
                    <div>
                        <img src={ Client2 } alt="" />
                    </div>
                    <div>
                        <img src={ Client3 } alt="" />
                    </div>
                    <div>
                        <img src={ Client4 } alt="" />
                    </div>
                </div>
            </div>
        </div>

    )

}
import React, { useEffect, useState } from "react";
import $ from 'jquery';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// IMAGES
import a90 from '../assets/media/base/a-90.png';
import t1 from '../assets/media/base/t1.png';

// ICONS
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

// ENDPOINT
import { BACK_ENDPOINT } from '../const.js';

export default function Faq() {

    const [ faq, setFaq ] = useState([]);

    const collapsible = (e) => {

        console.log(e)
        $(e.target).toggleClass('show-body');

    }

    const getFaq = async() => {

        await axios.get(BACK_ENDPOINT + 'faq/')
        .then(res => {

            setFaq(res.data);

        })
        .catch(err => {

            console.log(err)

        })

    }

    useEffect(() => {

        // SCROLL TOP
        window.scroll(0, 10);
        getFaq();

    }, [])

    const svg = (e) => {

        console.log(e.target)

    }

    return(

        <div>
            <div className="py-5 mt-5">
                <h1 className="text-center text-blue">PREGUNTAS FRECUENTES</h1><br/>
            </div>
            <div className="d-flex flex-wrap p-5 bg-img-devices-faq">
                <div className="col-12 col-lg-6 px-5 border-end-lg">
                    <div>
                        <h2 className="text-center">
                            COMPRA DE PUNTO DE VENTA BESTPAY
                        </h2>
                    </div>
                    <div className="mt-5 p-4">
                        <img src={ a90 } alt='Punto de venta A90' className="img-fluid" />
                    </div>
                </div>
                <div className="col-12 col-lg-6 ps-lg-5">
                    <ul className="collapsible faqs">
                        {

                            faq.map(f => 

                                f.id_theme === "1" ?

                                    <li key={ f.id }>
                                        <button type="button" className="collapsible-header text-white" onClick={ collapsible }>
                                            { f.question }
                                            <FontAwesomeIcon icon={ faChevronDown } className="ps-2 down-icon" onClick={ svg } />
                                        </button>
                                        <div className="collapsible-body">
                                            <p>
                                                { f.answer }
                                            </p>
                                        </div>
                                    </li>

                                :

                                    ""

                            )

                        }
                    </ul>
                </div>
            </div>
            <div className="d-flex flex-wrap flex-column-reverse flex-lg-row p-5 bg-img-support-faq">
                <div className="col-12 col-lg-6 pe-lg-5 border-end-lg">
                    <ul className="collapsible faqs">
                        {

                            faq.map(f => 

                                f.id_theme === "1" ?

                                    <li key={ f.id }>
                                        <button type="button" className="collapsible-header text-blue" onClick={ collapsible }>
                                            { f.question }
                                            <FontAwesomeIcon icon={ faChevronDown } className="ps-2 down-icon" />
                                        </button>
                                        <div className="collapsible-body">
                                            <p>
                                                { f.answer }
                                            </p>
                                        </div>
                                    </li>

                                :

                                    ""

                            )

                        }
                    </ul>
                </div>
                <div className="col-12 col-lg-6 px-5">
                    <div>
                        <h2 className="text-center">
                            SOBRE LOS EQUIPOS Y SOPORTE TÉCNICO
                        </h2>
                    </div>
                    <div className="mt-5 p-4">
                        <img src={ t1 } alt='Punto de venta A90' className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>

    )

}
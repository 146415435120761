import React, { useEffect, useState } from 'react';
import axios from 'axios';

// ICONS
import Our1 from '../assets/media/base/our-devices-1.webp';
import Our2 from '../assets/media/base/our-devices-2.webp';
import Our3 from '../assets/media/base/our-devices-3.webp';
import Our4 from '../assets/media/base/our-devices-4.webp';

export default function DevicesBenefits() {

    return(

        <div className='px-5 pt-5'>
            <div className='text-center'>
                <h2 className='text-blue'>
                    BENEFICIOS DE NUESTROS EQUIPOS
                </h2>
            </div>
            <div className='d-flex flex-wrap justify-content-center align-items mt-5 border-bottom-red pb-5'>
                <div className='col-12 col-md-4 col-lg-3 text-center'>
                    <img src={ Our1 } alt="Device protection" />
                    <div className='text-blue'>
                        Protección total en tus transacciones
                    </div>
                </div>
                <div className='col-12 col-md-4 col-lg-3 text-center'>
                    <img src={ Our2 } alt="Device standars" />
                    <div className='text-blue'>
                        Cumplen con los estándares de seguridad PCI
                    </div>
                </div>
                <div className='col-12 col-md-4 col-lg-3 text-center'>
                    <img src={ Our3 } alt="Device increments" />
                    <div className='text-blue'>
                        Aumenta tus ingresos de manera fácil
                    </div>
                </div>
                <div className='col-12 col-md-4 col-lg-3 text-center'>
                    <img src={ Our4 } alt="Device support" />
                    <div className='text-blue'>
                        Realizamos soporte técnico rápido
                    </div>
                </div>
            </div>
        </div>

    )

}
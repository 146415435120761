import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// ICONS
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

// IMAGES
import Call from '../assets/media/base/services/call-center.png';
import Avatar from '../assets/media/base/services/call-center-avatar.png';

export default function Services() {

    useEffect(() => {

        // SCROLL TOP
        window.scroll(0, 10);

    }, [])

    return(

        <div>
            <div className="py-5 mt-5">
                <h1 className="text-center text-blue">SERVICIOS</h1><br/>
            </div>
            <div className="p-5 bg-img-advisory">
                <div className="col-12 col-lg-6 me-auto text-center">
                    <h3 className='mb-5'>
                        ASESORÍA
                    </h3>
                    Te acompañamos desde el inicio y durante todo el proceso. Si requieres apertura de cuenta, gestión del código de afiliado, asesoría en la selección de tu equipo y servicio post venta, nosotros seremos tus mayores aliados.
                </div>
            </div>
            <div className="p-5 bg-img-post">
                <div className="col-12 col-lg-6 ms-auto text-center text-blue">
                    <h3 className='mb-5'>
                        SERVICIO POSTVENTA
                    </h3>
                    Te acompañamos desde el inicio y durante todo el proceso. Si requieres apertura de cuenta, gestión del código de afiliado, asesoría en la selección de tu equipo y servicio post venta, nosotros seremos tus mayores aliados.
                </div>
            </div>
            <div className="p-5 bg-img-service">
                <div className="col-12">
                    <h3 className='my-5 text-center'>
                        SERVICIO TÉCNICO
                    </h3>
                    <div className='d-flex flex-wrap p-3'>
                        <div className="col-12 col-lg-6">
                            <div>
                                <h5 className='mb-4'>
                                    SOPORTE DESDE EL CALL CENTER CON EL CLIENTE
                                </h5>
                            </div>
                            <div>
                                <ul>
                                    <li>Fallas de comunicación (IP/APN)</li>
                                    <li>Error en formato</li>
                                    <li>Conectar POS al TMS</li>
                                    <li>Cierre superior a 48 horas (error en cierre)</li>
                                    <li>Borrado de lotes y reversos</li>
                                    <li>Manejo de POS General</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div>
                                <h5 className='mb-4'>
                                    SOPORTE DESDE EL CALL CENTER CON EL CLIENTE
                                </h5>
                            </div>
                            <div>
                                <ul>
                                    <li>Error de crepitación</li>
                                    <li>Errores críticos de seguridad</li>
                                    <li>Tampered</li>
                                    <li>Falla de teclado</li>
                                    <li>Falla de pantalla</li>
                                    <li>Falla de lector de chip</li>
                                    <li>Reactivación</li>
                                    <li>Error en llaves</li>
                                    <li>Carga de parámetros de nueva venta</li>
                                    <li>Other error</li>
                                    <li>Cambio de banco y Razón Social</li>
                                    <li>Instalación de App</li>
                                    <li>Falla de impresora</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='mx-auto col-12 col-lg-6 mt-3'>
                        <img src={ Call } alt='Call Center' className='img-fluid mx-auto d-block' />
                    </div>
                </div>
            </div>
            <div className='py-5'>
                <div className='d-flex flex-wrap text-blue col-12 col-lg-10 mx-auto py-5 justify-content-center justify-content-lg-around'>
                    <div className='px-3'>
                        <img src={ Avatar } alt='' className='img-fluid mx-auto d-block pb-3' />
                        <h5 className='fw-bold text-center'>
                            OPERACIONES
                        </h5>
                        <ul className='list-unstyled'>
                            <li>
                                <FontAwesomeIcon icon={ faEnvelope } className='pe-2' />
                                <span>
                                    operaciones@bestpay.com.ve
                                </span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={ faWhatsapp } className='pe-2' />
                                <span>
                                    0424-2065487
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className='px-3'>
                        <img src={ Avatar } alt='' className='img-fluid mx-auto d-block pb-3' />
                        <h5 className='fw-bold text-center'>
                            VENTAS
                        </h5>
                        <ul className='list-unstyled'>
                            <li>
                                <FontAwesomeIcon icon={ faEnvelope } className='pe-2' />
                                <span>
                                    ventas@bestpay.com.ve
                                </span>
                            </li>
                            <li className='d-flex align-items-center'>
                                <FontAwesomeIcon icon={ faWhatsapp } className='pe-2' />
                                <div>
                                    <span>
                                        0414-2238847
                                    </span><br />
                                    <span>
                                        0414-2238849
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className='px-3'>
                        <img src={ Avatar } alt='' className='img-fluid mx-auto d-block pb-3' />
                        <h5 className='fw-bold text-center'>
                            CALL CENTER
                        </h5>
                        <ul className='list-unstyled'>
                            <li>
                                <FontAwesomeIcon icon={ faEnvelope } className='pe-2' />
                                <span>
                                    info@bestpay.com.ve
                                </span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={ faPhone } className='pe-2' />
                                <span>
                                    0212.3070200
                                </span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={ faWhatsapp } className='pe-2' />
                                <span>
                                    0414-2238845
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className='px-3'>
                        <img src={ Avatar } alt='' className='img-fluid mx-auto d-block pb-3' />
                        <h5 className='fw-bold text-center'>
                            COBRANZAS
                        </h5>
                        <ul className='list-unstyled'>
                            <li>
                                <FontAwesomeIcon icon={ faEnvelope } className='pe-2' />
                                <span>
                                    cobranza@bestpay.com.ve
                                </span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={ faWhatsapp } className='pe-2' />
                                <span>
                                    0424-2065514
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    )

}
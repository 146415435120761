import React from 'react';
import { Link } from 'react-router-dom';

// IMAGES
import Device from '../assets/media/base/a-90.png'

export default function Header() {

    return(

        <header>
            <div className='d-flex align-items-center flex-column flex-sm-row'>
                <div className='col-sm-6 col-md-5 d-flex flex-column justify-content-center px-5 pe-sm-0 m-auto m-sm-0'>
                    <h1 className='text-white'>
                        PUNTOS DE VENTA EN VENEZUELA 
                    </h1>
                    <div className='d-flex align-items-center text-white text-justify mt-3'>
                        <p>
                            En Bestpay, no solo te ofrecemos los mejores puntos del mercado, sino que te acompañamos con una atención personalizada y eficiente.
                        </p>
                    </div>
                    <div className='mt-3'>
                        <Link className='text-decoration-none text-white border-red border-45 border-2 px-2 py-2 btn-shadow' style={{ 'fontWeight': 'bold' }} to={ '/productos' }>
                            Adquiere tu punto de venta 
                        </Link>
                    </div>
                </div>
                <div className='col-sm-6 col-md-5 d-flex justify-content-center align-items-center pe-md-0 m-auto m-sm-0'>
                    <img src={ Device } />
                </div>
            </div>
        </header>

    )

}
let page = window.location.hostname;
let BACK_ENDPOINT = '';

if(page === 'localhost') {

    BACK_ENDPOINT = 'http://localhost/grapps/api/bestpay/';

} else {

    BACK_ENDPOINT = 'https://bestpay.com.ve/api/';

}

export { BACK_ENDPOINT }
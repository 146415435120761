import React, { useEffect, useState } from 'react';
import axios from 'axios';

// CONST
import { BACK_ENDPOINT } from '../const';

export default function AliatesBanks() {

    const [ banks, setBank ] = useState([]);

    const getBanks = async() => {

        await axios.get(BACK_ENDPOINT + 'banks_logos/')
        .then(res => {

            setBank(res.data)

        })
        .catch(err => {

            console.log(err)

        })

    }
    
    useEffect(() => {

        getBanks();

    }, [])

    return(

        <div className='px-5 pt-5'>
            <div className='text-center'>
                <h2 className='text-blue'>
                    BANCOS ALIADOS
                </h2>
            </div>
            <div className='d-flex flex-wrap align-items-center justify-content-center border-bottom-red pb-5 mt-5'>
                {
                    banks.map(bank => (
                        <div className='col-12 col-md-4 col-lg-3 p-2 text-center' key={ bank.id }>
                            <img src={ BACK_ENDPOINT + 'media/banks/' + bank.img + '.webp' } className='w-75' />
                        </div>
                    ))
                }
            </div>
        </div>

    )

}
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';

import { BACK_ENDPOINT } from '../const';

export default function RecursContact(props) {

    const [ data, setData ] = useState({});
    const [ send, setSend ] = useState("");  
    const [ btnText, setBtnText ] = useState("Enviar");

    const { cities, products } = props;

    const handleSubmit = async(e) => {

        e.preventDefault();
        $('.spinner-border').removeClass('d-none');
        setBtnText('Enviando...');
        await axios.post(BACK_ENDPOINT + 'mails/', data)
        .then(res => {

            setSend("Correo electrónico enviado correctamente");
            setBtnText('Enviar');
            $('.spinner-border').addClass('d-none')
            $('#result').css('background-color', 'green');
            $('#result').css('color', 'white');
            setTimeout(() => {

                setSend("");

            }, 4000)
            e.target.reset();


        })
        .catch(err => {

            console.log(err);

        })

    }

    const handleChange = (e) => {

        setData({

            ...data,
            [e.target.id ]: e.target.value

        })

    }

    useEffect(() => {


    }, [])

    function toUpperCase(str) {
        return str.toUpperCase();
    }

    return(

        <div className="recurs-contact-form">
            <div className="d-flex col-12 p-5 flex-column-reverse flex-lg-row align-items-center">
                <div className="col-12 col-lg-6 py-3">
                    <div className='d-block mb-3 px-4 rounded-pill' id='result'>
                        <span>
                            {
                                send
                            }
                        </span>
                    </div>
                    <form onSubmit={ handleSubmit }>
                        <div className="col-12">
                            <div className="d-flex mb-3 align-items-center flex-column flex-md-row">
                                <div className="col-12 col-md-6 px-2">
                                    <input type="text" className="border-45 bg-transparent border-white border-2 py-1 px-3 w-100 mb-3 mb-md-0" placeholder="Nombre y Apellido *" id='name' onChange={ handleChange } />
                                </div>
                                <div className="col-12 col-md-6 px-2">
                                    <input type='email' className="border-45 bg-transparent border-white border-2 py-1 px-3 w-100" placeholder="Correo Electrónico *" id='email' onChange={ handleChange } required />
                                </div>
                            </div>
                            <div className="d-flex mb-3 align-items-center flex-column flex-md-row">
                                <div className="col-12 col-md-6 px-2">
                                    <input type="text" className="border-45 bg-transparent border-white border-2 py-1 px-3 w-100 mb-3 mb-md-0" placeholder="Teléfono *" id='cell' onChange={ handleChange } required />
                                </div>
                                <div className="col-12 col-md-6 px-2">
                                    <select className="border-45 bg-transparent border-white border-2 py-2 w-100 px-3" id='product' onChange={ handleChange } required>
                                        <option>
                                            -- Selecciona tu producto --
                                        </option>
                                        {

                                            products.map(p => 

                                                <option key={ p.id }>
                                                    { toUpperCase(p.name) }
                                                </option>

                                            )

                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-3 flex-column flex-md-row">
                                <div className="col-12 col-md-6 px-2">
                                    <select className="border-45 bg-transparent border-white border-2 py-2 px-3 mb-3 w-100 mb-md-0" id='city' onChange={ handleChange } required>
                                        <option>
                                            -- Ciudad --
                                        </option>
                                        {

                                            cities.map(c => 

                                                <option key={ c.id }>
                                                    { c.city }
                                                </option>

                                            )

                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex align-items-center px-2 mb-3 flex-column flex-lg-row">
                                <textarea className="border-45 bg-transparent border-white border-2 p-3 w-100 mb-3 mb-lg-0" rows={ 6 } placeholder="Escriba su mensaje..." id='content' onChange={ handleChange } required>
                                </textarea>
                            </div>
                            <div>
                                <button type="submit" className="bg-transparent border-2 border-white border-45 px-4 text-white py-1 d-flex align-items-center">
                                    { btnText }
                                    <div className="spinner-border ms-2 d-none" role="status">
                                        <span className="visually-hidden">Cargando...</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="py-3 col-12 col-lg-6 px-5">
                    <div className="text-center text-white">
                        <h2>
                            ¿NO TIENES NÚMERO DE AFILIACIÓN Y NECESITAS UN PUNTO DE VENTA?
                        </h2>
                        <span className='d-block mt-5'>
                            Ingresa tus datos para que uno de nuestros asesores te apoye con la gestión de un número de afiliación
                        </span>
                    </div>
                </div>
            </div>
        </div>

    )

}
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import $ from 'jquery';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ICONS 
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faX } from "@fortawesome/free-solid-svg-icons";

// CSS
import './App.css';
import './css/app.css';

// PAGES
import Index from "./pages/Index";
import About from './pages/About';
import Alliates from "./pages/Alliates";
import Services from "./pages/Services";
import Recurs from "./pages/Recurs";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import Banks from "./pages/Banks";
import Products from "./pages/Products";
import Buy from "./pages/Buy";

// CONST 
import { BACK_ENDPOINT } from './const';

// COMPONENTS
import Navbar from "./base/Navbar";
import Footer from "./base/Footer";

// POPUP
import TrackingPopup from "./layouts/TrackingPopup";

function App() {

  const [ cities, setCities ] = useState([]);
  const [ products, setProducts ] = useState([]);
  const [ tracking, setTracking ] = useState({});
  const [ promo, setPromo ] = useState([]);

  const getCities = () => {

    axios.get(BACK_ENDPOINT + 'cities/')
    .then(res => {

      setCities(res.data)

    })
    .catch(err => {

        console.log(err)

    })

  }

  const getProducts = () => {

      axios.get(BACK_ENDPOINT + 'products/')
      .then(res => {

        setProducts(res.data)

      })
      .catch(err => {

        console.log(err)

      })

  }

  const showPopup = (data) => {

    setTracking(data);
    $('.modal').show();

  }

  const getPromo = () => {

    axios.get(BACK_ENDPOINT + 'promotion/')
    .then(res => {

      setPromo(res.data)

    })
    .catch(err => {

      console.log(err)

    })

  }

  const closePromo = e => {

    $('#promoPopup').addClass('d-none')

  }
  
  useEffect(() => {

    // SCROLL TOP
    window.scroll(0, 0);

    getCities();
    getProducts();
    getPromo();

    return() => {

      window.addEventListener("beforeunload", deleteCookies);

    }

  }, [])

  const deleteCookies = () => {
    // Obtener todas las cookies del sitio web
    var cookies = document.cookie.split(";");
    // Recorrer cada cookie y asignarle un valor vacío y una fecha de expiración pasada
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  };

  return (
    <div className="App container-fluid px-0">
      <a type="button" className="ws position-fixed p-2 text-decoration-none" href='https://wa.me/584142238845' target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={ faWhatsapp } />
      </a>
      <BrowserRouter>
        {

          Object.keys(tracking).length > 0 ? <TrackingPopup tracking={ tracking } /> : ''

        }
        {
          Object.keys(promo).length > 0 ? <div id='promoPopup' className="w-100 vh-100 position-fixed top-0 bg-promo" style={{ 'zIndex': '999999' }}><img className="position-absolute promo-img" style={{ 'zIndex': '999999' }} src={ BACK_ENDPOINT + 'media/promotion/promo.png' } /><button onClick={ closePromo } className="icon-promo" type='button'><FontAwesomeIcon icon={ faX } /></button></div> : ''
        }
        <Navbar show={ showPopup } />
        <Routes>
          <Route path="*" element={ <Navigate to='/' replace /> } />
          <Route path="/" element={ <Index cities={ cities } products={ products } /> } />
          <Route path="/empresa" element={ <About /> } />
          <Route path="/aliados-comerciales" element={ <Alliates /> } />
          <Route path="/servicios" element={ <Services /> } />
          <Route path="/recaudos" element={ <Recurs cities={ cities } products={ products } /> } />
          <Route path="/contactos" element={ <Contact cities={ cities } products={ products } /> } />
          <Route path="/preguntas-frecuentes" element={ <Faq /> } />
          <Route path="/bancos-aliados" element={ <Banks /> } />
          <Route path="/productos" element={ <Products products={ products } /> } />
          <Route path="/productos/adquirir/:id" element={ <Buy /> } />
        </Routes>
        <Footer products={ products } />
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import $ from 'jquery';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// CONST
import { BACK_ENDPOINT } from "../const";

// ICONS
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const ProductsCarousel = ( img ) => {
  const [index, setIndex] = useState(0);
  const [ left, setLeft ] = useState(0)

  let photos = img.photos;

  // Create a listener for the "next" button
  const nextSlide = () => {
    $('#slider').css('animation', 'none');
    let carouselSize = $('.carousel').width();
    let margin = left;
    let append = 0;
    if (carouselSize <= 540 && margin > -1400) {

      append = 350;
      let marginEnd = left - append;
      setLeft(marginEnd);
      document.getElementById('slider').style.marginLeft = marginEnd + "px";

    }
    if (margin > -600){

      if (carouselSize === 1200){
        append = 400;
      }
        
      if (carouselSize === 900){
        append = 300;
      }

      if (carouselSize === 500){
        append = 250;
      }

      let marginEnd = left - append;
      setLeft(marginEnd);
      document.getElementById('slider').style.marginLeft = marginEnd + "px";

    }
  };

  const prevSlide = () => {
    $('#slider').css('animation', 'none');
    let carouselSize = $('.carousel').width();
    let margin = left;
    if (margin < 0){
      
      let append = 0;
      if (carouselSize === 1200){
        append = 400;
      }
        
      if (carouselSize === 900){
        append = 300;
      }

      if (carouselSize === 500){
        append = 250;
      }
        
      if (carouselSize === 350){
        append = 350;
      }

      let marginEnd = left + append;
      setLeft(marginEnd);
      document.getElementById('slider').style.marginLeft = marginEnd + "px";

    }
  };

  useEffect(() => {
    // Get the number of slides
    const numSlides = photos.length;

    // Set the initial slide index
    setIndex(0);

  }, [photos]);

  return (
    <div className="carousel carousel-top py-5 position-relative">
      <button type='button' className="position-absolute" id='left-arrow' onClick={ prevSlide }>
        <span>
          <FontAwesomeIcon icon={ faChevronLeft } />
        </span>
      </button>
      <div id='slider'>
        {photos.map((photo, i) => (
          <div key={i}>
            <img src={ BACK_ENDPOINT + 'media/products/' + photo.img } alt={ photo } />
            <div className="text-center mt-4">
              <Link type='button' className="border-45 bg-blue px-4 py-1 text-white" style={{ 'textDecoration': 'none' }} to={ '/productos/adquirir/' + photo.id }>
                Adquirir
              </Link>
            </div>
          </div>
        ))}
        <div className="d-flex align-items-center justify-content-center mx-auto">
          <Link type='button' className="border-45 bg-blue px-4 py-1 text-white" style={{ 'textDecoration': 'none' }} to={ '/productos' }>
            Ver más
          </Link>
        </div>
      </div>
      <button type='button' className="position-absolute" id='right-arrow' onClick={ nextSlide }>
        <span>
          <FontAwesomeIcon icon={ faChevronRight } />
        </span>
      </button>
    </div>
  );
};

export default ProductsCarousel;
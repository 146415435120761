import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// IMAGES
import PTP from '../assets/media/base/alliates/ptp.png';
import Dipalca from '../assets/media/base/alliates/dipalca.png';
import Ranch from '../assets/media/base/alliates/ranch.png';

// ICONS
import { faLocationDot, faPhone, faBugSlash } from "@fortawesome/free-solid-svg-icons";

export default function Alliates() {

    useEffect(() => {

        // SCROLL TOP
        window.scroll(0, 10);

    }, [])

    return(

        <div>
            <div className="py-5 mt-5">
                <h1 className="text-center text-blue">ALIADOS COMERCIALES</h1><br/>
            </div>
            <div id='first' className="d-flex flex-wrap p-5 align-items-center">
                <div className="col-12 col-md-3 text-center">
                    <img src={ PTP } style={{ 'width': '70%' }} />
                </div>
                <div className="col-12 col-md-9 p-md-4 mt-3 mt-md-0">
                    <ul className="ps-0">
                        <li className="d-flex align-items-center mb-3">
                            <span className="pe-2">
                                <FontAwesomeIcon icon={ faLocationDot } style={{ 'width': '16px' }} />
                            </span>
                            <div className="col-12 col-md-6">
                                <span className="small">
                                    <strong>ZULIA</strong> / AV. 8 SANTA RITA CON CALLE 59 MARACAIBO, EDIFICIO PEMAN, PRIMER PISO, LOCAL NÚMERO 5. FRENTE AL ITALMARMOL. MARACAIBO - ZULIA. 
                                </span>
                            </div>
                        </li>
                        <li className="d-flex align-items-center mb-3">
                            <span className="pe-2 w-auto">
                                <FontAwesomeIcon icon={ faPhone } style={{ 'width': '16px' }} />
                            </span>
                            <div className="col-12 col-md-6 d-flex align-items-center">
                                <span className="small">
                                    04146874316 / 04127898442
                                </span>
                            </div>
                        </li>
                        <li className="d-flex align-items-center mb-3">
                            <span className="pe-2 w-auto">
                                <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" width={ 16 } viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                            </span>
                            <div className="col-12 col-md-6 d-flex align-items-center">
                                <span className="small">
                                    @PUNTPAGO
                                </span>
                            </div>
                        </li>
                        <li className="d-flex align-items-center mb-3">
                            <span className="pe-2">
                                <FontAwesomeIcon icon={ faBugSlash } style={{ 'width': '16px' }} />
                            </span>
                            <div className="col-12 col-md-6 d-flex align-items-center">
                                <span className="small">
                                    <strong>NO</strong>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div id='second' className="d-flex flex-wrap p-5 align-items-center">
                <div className="col-12 col-md-9 p-md-4 mt-3 mt-md-0 justify-content-end">
                    <ul className="ps-0">
                        <li className="d-flex align-items-center justify-content-end mb-3">
                            <span className="pe-2">
                                <FontAwesomeIcon icon={ faLocationDot } style={{ 'width': '16px' }} />
                            </span>
                            <div className="col-12 col-md-6 d-flex align-items-center">
                                <span className="small">
                                    <strong>APURE</strong> / CENTRO COMERCIAL VILLA CENTER, LOCAL 15. SAN FERNANDO DE APURE - APURE.
                                </span>
                            </div>
                        </li>
                        <li className="d-flex align-items-center mb-3 justify-content-end">
                            <span className="pe-2 w-auto">
                                <FontAwesomeIcon icon={ faPhone } style={{ 'width': '16px' }} />
                            </span>
                            <div className="col-12 col-md-6 d-flex align-items-center">
                                <span className="small">
                                    04243039423 / 04127785581 / 02473430655
                                </span>
                            </div>
                        </li>
                        <li className="d-flex align-items-center mb-3 justify-content-end">
                            <span className="pe-2 w-auto">
                                <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" width={ 16 } viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                            </span>
                            <div className="col-12 col-md-6 d-flex align-items-center">
                                <span className="small">
                                    @IMPORTPAGORANCHSCA
                                </span>
                            </div>
                        </li>
                        <li className="d-flex align-items-center mb-3 justify-content-end">
                            <span className="pe-2">
                                <FontAwesomeIcon icon={ faBugSlash } style={{ 'width': '16px' }} />
                            </span>
                            <div className="col-12 col-md-6 d-flex align-items-center">
                                <span className="small">
                                    SERVICIO TÉCNICO: <strong>NO</strong>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-3 text-center">
                    <img src={ Ranch } style={{ 'width': '60%' }} />
                </div>
            </div>
            <div id='third' className="d-flex flex-wrap p-5 align-items-center">
                <div className="col-12 col-md-3 text-center">
                    <img src={ Dipalca } style={{ 'width': '80%' }} />
                </div>
                <div className="col-12 col-md-9 p-md-4 mt-3 mt-md-0">
                    <ul className="ps-0">
                        <li className="d-flex align-items-center mb-3">
                            <span className="pe-2">
                                <FontAwesomeIcon icon={ faLocationDot } style={{ 'width': '16px' }} />
                            </span>
                            <div className="col-12 col-md-6 d-flex align-items-center">
                                <span className="small">
                                    <strong>YARACUY</strong> / CENTRO COMERCIAL BUENAVENTURA, LOCAL A-50, PLANTA BAJA. ARAURE - PORTUGUESA.
                                </span>
                            </div>
                        </li>
                        <li className="d-flex align-items-center mb-3">
                            <span className="pe-2 w-auto">
                                <FontAwesomeIcon icon={ faPhone } style={{ 'width': '16px' }} />
                            </span>
                            <div className="col-12 col-md-6 d-flex align-items-center">
                                <span className="small">
                                    04141594715 / 04142233315
                                </span>
                            </div>
                        </li>
                        <li className="d-flex align-items-center mb-3">
                            <span className="pe-2 w-auto">
                                <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" width={ 16 } viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                            </span>
                            <div className="col-12 col-md-6 d-flex align-items-center">
                                <span className="small">
                                    @DIPALCA_C.A
                                </span>
                            </div>
                        </li>
                        <li className="d-flex align-items-center mb-3">
                            <span className="pe-2">
                                <FontAwesomeIcon icon={ faBugSlash } style={{ 'width': '16px' }} />
                            </span>
                            <div className="col-12 col-md-6 d-flex align-items-center">
                                <span className="small">
                                    <strong>NO</strong>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    )

}
import React, { useEffect, useState } from 'react';
import axios from 'axios';

// CONST
import { BACK_ENDPOINT } from "../const";

// LAYOUT
import Product from '../layouts/Product';

export default function Products(props) {

    const [ allProducts, setProducts ] = useState();
    const [ product, setProduct ] = useState([]);
    const [ batteryData, setBatteryData ] = useState();

    const { products } = props;

    const split_battery_data = (bat) => {

        let battery_data = bat.split('/');
        setBatteryData(battery_data);

    }

    const getProduct = async(id) => {

        await axios.get(BACK_ENDPOINT + 'products/index.php?id=' + id)
        .then(res => {

            setProduct(res.data)
            split_battery_data(res.data.product.battery)

        })
        .catch(err => {

            console.log(err)

        })

    }

    useEffect(() => {

        // SCROLL TOP
        window.scroll(0, 10);
        setProducts(products)

    }, [products])

    function toUpperCase(str) {
        return str.toUpperCase();
    }

    const getProductInfo = (e) => {

        setProduct([]);
        getProduct(e.target.id);

    }

    const scroll = (pos) => {

        window.scroll(0, pos.top - 100)

    }

    return(

        <div>
            <div className="py-5 mt-5">
                <h1 className="text-center text-blue">PRODUCTOS</h1><br/>
            </div>
            <div className='d-flex flex-wrap justify-content-around align-items-center mb-5'>
                <div className='col-12 mb-4 ps-5'>
                    <a className='border-2 text-decoration-none border-45 border-gray bg-white shadow px-3 py-1' href={ BACK_ENDPOINT + 'documents/catalogo.pdf' } target='_blank' rel="noopener noreferrer" download="Catálogo.pdf">
                        Descarga nuestro catálogo
                    </a>
                </div>
                {
                    products.map(p =>

                        <div className='text-center pb-3' key={ p.id }>
                            <button type='button' className='border-2 border-45 border-gray bg-white shadow p-2 hoverable-product position-relative' id={ p.id } onClick={ getProductInfo }>
                                {
                                    p.id === '7' ? 

                                        <span className='position-absolute no-stock-brand'>
                                            <span>
                                                Próximamente
                                            </span>
                                        </span>

                                    : ''
                                }
                                <div>
                                    <img src={ BACK_ENDPOINT + 'media/products/' + p.img } className='products-img' id={ p.id } />
                                </div>
                                <div>
                                    <h5 className='text-blue'>
                                        {
                                            toUpperCase(p.name)
                                        }
                                    </h5>
                                </div>
                            </button>
                        </div>
                        
                    )
                }
            </div>
            {
                Object.keys(product).length > 0 ?
                    <Product product={ product } battery={ batteryData } scroll={ scroll } />
                :
                    ''

            }
        </div>
        
    )

}
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// ICONS
import { faWifi, faCamera, faQrcode, faBatteryFull, faPrint, faMemory } from '@fortawesome/free-solid-svg-icons';
import { faBluetoothB, faCcMastercard, faCcVisa, faAndroid } from '@fortawesome/free-brands-svg-icons';
import PCI from '../assets/media/base/icons/pcisecuritystandards_logo_icon_168912 1.png';

// CONST
import { BACK_ENDPOINT } from "../const";

export default function Product(props) {

    const [ tech, setTech ] = useState();

    useEffect(() => {

        setTech(props.product.tech)

        let product_container_pos = document.getElementById('product').getBoundingClientRect();
        props.scroll(product_container_pos)

    }, [])

    function toUpperCase(str) {
        return str.toUpperCase();
    }

    return(

        <div>
            {

                props.product ?  
                
                    <div id='product'>
                        <div className='col-12 d-flex align-items-center flex-wrap justify-content-around mb-5'>
                            <div className='col-12 col-lg-6 text-center px-5'>
                                <h2 className='text-blue'>
                                   {
                                    props.product.product.name
                                   } 
                                </h2>
                                <span className='text-blue'>
                                    {
                                        toUpperCase(props.product.product.description)
                                    }
                                </span>
                                <div className='mt-4'>
                                    {
                                        props.product.product.id !== '7' ?

                                            <Link className='text-decoration-none text-blue border-red border-45 border-2 px-5 py-2 btn-shadow' style={{ 'fontWeight': 'bold' }} to={ 'adquirir/' + props.product.product.id }>
                                                Comprar
                                            </Link>

                                        : 
                                        
                                            <span className='text-decoration-none text-blue border-red border-45 border-2 px-5 py-2 btn-shadow' style={{ 'fontWeight': 'bold' }}>
                                                PRÓXIMAMENTE
                                            </span>
                                    }
                                </div>
                            </div>
                            <div className='col-12 col-lg-6 text-center'>
                                <img  src={ BACK_ENDPOINT + 'media/products/' + props.product.product.img } className='img-fluid mt-4' />
                            </div>
                        </div>
                        <div className='bg-tech p-4'>
                            <div className='col-12 col-lg-6 ms-auto text-center'>
                                <h4>
                                    TRANSACCIONES EN TIEMPO REAL
                                </h4>
                                <div>
                                    <p>
                                        CON EL { toUpperCase(props.product.product.name) } JAMÁS PERDERÁS UNA VENTA GRACIAS A SU PLATAFORMA MULTICONEXIÓN {

                                            toUpperCase(props.product.tech.map((e) => e.name).join(', '))

                                        }.
                                    </p>
                                </div>
                                <div className='d-flex flex-wrap justify-content-around mt-4'>
                                    {

                                        props.product.tech.map((e) => 
                                        
                                            e.name === 'Wi-Fi' ? 

                                                <div key={ e.id }>
                                                    <FontAwesomeIcon icon={ faWifi } style={{ 'fontSize': '2rem' }} />
                                                    <div>
                                                        <span>
                                                            WiFi
                                                        </span>
                                                    </div>
                                                </div>

                                            :

                                            e.name === '4G' ? 

                                                <div key={ e.id }>
                                                    <FontAwesomeIcon icon={ faWifi } style={{ 'fontSize': '2rem' }} />
                                                    <div>
                                                        <span>
                                                            4G
                                                        </span>
                                                    </div>
                                                </div>

                                            :

                                            e.name === '3G' ? 

                                                <div key={ e.id }>
                                                    <FontAwesomeIcon icon={ faWifi } style={{ 'fontSize': '2rem' }} />
                                                    <div>
                                                        <span>
                                                            3G
                                                        </span>
                                                    </div>
                                                </div>

                                            :

                                            e.name === 'Bluetooth' ? 

                                                <div key={ e.id }>
                                                    <FontAwesomeIcon icon={ faBluetoothB } style={{ 'fontSize': '2rem' }} />
                                                    <div>
                                                        <span>
                                                            Bluetooth
                                                        </span>
                                                    </div>
                                                </div>

                                            :

                                            ''
                                        
                                        )

                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-12 d-flex flex-wrap align-items-center text-blue py-5'>
                            <div className='text-center col-12 col-lg-4 px-4'>
                                <img  src={ BACK_ENDPOINT + 'media/products/' + props.product.product.img } className='img-fluid pb-5' />
                            </div>
                            <div className='col-12 col-lg-8 px-4 text-center'>
                                <h4 style={{ 'fontWeight': 'bold' }}>
                                    SEGURIDAD Y PROTECCIÓN TOTAL
                                </h4>
                                <div>
                                    REALIZA TODAS TUS VENTAS DE FORMA SEGURA, PROTEGIENDO TUS DATOS EN CADA TRANSACCIÓN GRACIAS A QUE CONTAMOS CON CERTIFICADO INTERNACIONAL PCI.
                                </div>
                                <div className='d-flex flex-wrap align-items-center justify-content-around mt-3 mb-5'>
                                    <FontAwesomeIcon icon={ faCcMastercard } style={{ 'fontSize': '4rem' }} />
                                    <FontAwesomeIcon icon={ faCcVisa } style={{ 'fontSize': '4rem' }} />
                                    <img src={ PCI } style={{ 'width': '5rem' }} />
                                </div>
                                <h4 style={{ 'fontWeight': 'bold' }}>
                                    VELOCIDAD Y PODER
                                </h4>
                                <div>
                                    CUENTA CON UN SISTEMA OPERATIVO { toUpperCase(props.product.product.so) }, BRINDANDO UN MEJOR RENDIMIENTO YA QUE PERMITE LA FUNCIÓN DE MULITITAREAS. { props.product.product.screen.length > 0 ? "CUENTA CON UNA PANTALLA " + toUpperCase(props.product.product.screen) + '.' : '' }
                                </div>
                                <div className='d-flex flex-wrap align-items-center justify-content-around mt-3'>
                                    <div>
                                        <FontAwesomeIcon icon={ faAndroid } style={{ 'fontSize': '4rem' }} />
                                        <div>
                                            { toUpperCase(props.product.product.so) }
                                        </div>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={ faCamera } style={{ 'fontSize': '4rem' }} />
                                        <div>
                                            { toUpperCase(props.product.product.cam) }
                                        </div>
                                    </div>
                                    {

                                        props.product.readers.map(e => 
                                        
                                            e.name === 'QR' ? 

                                                <div>
                                                    <FontAwesomeIcon icon={ faQrcode } style={{ 'fontSize': '4rem' }} />
                                                    <div>
                                                        LECTOR { toUpperCase(e.name) }
                                                    </div>
                                                </div>  

                                            : ''
                                            
                                        )

                                    }
                                </div>
                            </div>
                        </div>
                        <div className='bg-extra p-4'>
                            <div className='col-12 col-lg-6 me-auto text-center'>
                                <h4>
                                    RENDIMIENTO EXCEPCIONAL
                                </h4>
                                <div>
                                    {

                                        props.battery.length > 1 ?

                                            <p>
                                                   PUEDE ESTAR EN ESPERA DURANTE { toUpperCase(props.battery[2]) } CON UNA SOLA CARGA, LO QUE BRINDA UN SOPORTE SÓLIDO PARA SU NEGOCIO.

                                                {
                                                    props.product.product.printer.length > 0 ? 
                                                    
                                                    <span>
                                                        <span> </span>ADEMÁS, CUENTA CON UNA <span></span>
                                                        { toUpperCase(props.product.product.printer) }.
                                                    </span>
                                                    
                                                    : ''
                                                }
                                            </p>


                                        : 

                                            props.product.product.printer.length > 0 ? 
                                                    
                                                <span>
                                                    <span> </span>CUENTA CON UNA <span></span>
                                                    { toUpperCase(props.product.product.printer) }.
                                                </span>
                                            :

                                            'NO PIERDAS LA OPORTUNIDAD DE OBTENER ESTE MARAVILLOSO DISPOSITIVO.'

                                    }
                                </div>
                                <div className='d-flex flex-wrap justify-content-around mt-4'>
                                    {
                                        props.battery.length > 1 ?
                                        
                                            <div>
                                                <FontAwesomeIcon icon={ faBatteryFull } style={{ 'fontSize': '3rem' }} />
                                                <div>
                                                    <span>
                                                        {
                                                            toUpperCase(props.battery[2])
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        
                                        : ''
                                    }
                                    {
                                        props.product.product.printer.length > 0 ?
                                        
                                            <div>
                                                <FontAwesomeIcon icon={ faPrint } style={{ 'fontSize': '3rem' }} />
                                                <div>
                                                    <span>
                                                        {
                                                            toUpperCase(props.product.product.printer)
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        
                                        : ''
                                    }
                                    {
                                        props.product.product.rom.length > 0 ?
                                        
                                            <div>
                                                <FontAwesomeIcon icon={ faMemory } style={{ 'fontSize': '3rem' }} />
                                                <div>
                                                    <span>
                                                        {
                                                            toUpperCase(props.product.product.rom)+'Gb ROM'
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        
                                        : ''
                                    }
                                    {
                                        props.product.product.ram.length > 0 ?
                                        
                                            <div>
                                                <FontAwesomeIcon icon={ faMemory } style={{ 'fontSize': '3rem' }} />
                                                <div>
                                                    <span>
                                                        {
                                                            toUpperCase(props.product.product.ram)+'Gb RAM'
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                : 

                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Cargando...</span>
                    </div>

            }
        </div>

    )

}
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// IMAGES
import Us from '../assets/media/base/about-us/us-icon.png';

export default function About() {

    useEffect(() => {

        // SCROLL TOP
        window.scroll(0, 10);

    }, [])

    return(

        <div>
            <div className="py-5 mt-5">
                <h1 className="text-center text-blue">NOSOTROS</h1><br/>
            </div>
            <div className="d-flex flex-wrap px-5 pb-5 align-items-center">
                <div className="col-12 col-lg-8 px-lg-5">
                    <h3 className="text-blue mb-4">
                        ¿QUIÉNES SOMOS?
                    </h3>
                    <p className="text-blue" style={{ 'textAlign': 'justify' }}>
                        <strong>BestPay</strong> es una empresa dedicada a desarrollar soluciones transaccionales y de medios de pago a una amplia gama de clientes particulares, comerciales y empresariales, diseñando nuevas experiencias que favorezcan la digitalización de los pagos y ofreciendo tarifas ajustadas a las necesidades de cada cliente. Contamos con un equipo de expertos dedicados a proporcionar a nuestros afiliados excelentes herramientas y servicio postventa que generen resultados de impacto positivo en la calidad de sus negocios con rapidez y eficiencia.
                    </p>
                </div>
                <div className="col-12 col-lg-4 px-lg-5 text-center">
                    <img src={ Us } style={{ 'width': '200px' }} />
                </div>
            </div>
            <div className="p-5 bg-img-us d-flex flex-wrap">
                <div className="col-12 col-lg-6 text-center px-3 mb-3 mb-lg-0">
                    <h3 className="mb-3">VISIÓN</h3>
                    Ser la empresa líder en medios de pago contribuyendo al éxito de la transformación digital de la sociedad apostando a la innovación y a la calidad de servicio de todos nuestros aliados.
                </div>
                <div className="col-12 col-lg-6 text-center px-3">
                    <h3 className="mb-3">MISIÓN</h3>
                    Desarrollar las mejores soluciones tecnológicas de medios de pagos a cliente particulares, comerciales y empresariales con la mayor seguridad, eficiencia y comodidad del mercado.
                </div>
            </div>
            <div className="pb-5">
                <div className="pt-5 px-5">
                    <h3 className="text-center text-blue">NUESTRAS FORTALEZAS</h3><br/>
                </div>
                <div className="col-12 col-lg-8 mx-auto text-blue">
                    <p className="text-center">
                        Nos enfocamos en la calidad de servicio, cuyo aspecto diferenciador será siempre el buen trato y la cercanía con nuestros clientes, enalteciendo sus decisiones. Nuestros valores son:
                    </p>
                </div>
            </div>
            <div className="pb-5">
                <div className="px-5">
                    <h3 className="text-center text-blue">VALORES</h3><br/>
                </div>
                <div className="col-12 col-lg-8 mx-auto text-blue">
                    <ul>
                        <li>
                            <strong>Versatilidad:</strong> Diseñamos procesos eficientes y adaptables al entorno, porque entendemos que el cliente requiere respuestas rápidas y oportunidad a sus necesidades 
                        </li>
                        <li>
                            <strong>Honestidad:</strong> Somos transparentes en las respuestas a nuestro clientes, queremos que sientan y sepan que estamos para servirles, atenderles y responderles oportunamente. 
                        </li>
                        <li>
                            <strong>Dinamismo y Profesionalidad:</strong> Contamos con un talento humano de alto desempeño, entusiasta y dinámico con capacidad de respuesta inmediata y amplia experticia dentro de cada una de las unidades de negocio. 
                        </li>
                        <li>
                            <strong>Empatía:</strong> Nos relacionamos y actuamos con la intención de reconocer y comprender los sentimientos y emociones de los demás, intentando experimentar de forma objetiva y racional lo que siente cada uno de los involucrados en nuestra cadena de valor.
                        </li>
                        <li>
                            <strong>Responsabilidad:</strong> Somos conscientes del impacto que genera nuestros tiempos de respuesta en su dinámica diaria, es por ello que somos responsable en el cumplimiento de nuestros compromisos, siempre enfocados en la mejor calidad de servicio.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="bg-cover-regions p-5">
                <div className="col-12 col-lg-6 text-center">
                    <h3>
                        REGIONES DE COBERTURA
                    </h3>
                    <div className="mt-5">
                        <Link className="bg-transparent border-2 border-white border-45 px-4 text-white py-1" style={{ 'textDecoration': 'none' }} to='/aliados-comerciales'>
                            Ver aliados
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    
    )

}
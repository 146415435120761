import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import axios from 'axios';

// LOGO
import White from '../assets/media/base/w-logo.png';
import Color from '../assets/media/base/logo.png';

// ICONS
import { faSearch, faBars, faCircleXmark, faX } from '@fortawesome/free-solid-svg-icons';

// CONST
import { BACK_ENDPOINT } from '../const';

export default function Navbar(props) {

    const [ ci, setCi ] = useState('');

    $(window).on('scroll', () => {

        let page = window.location.href;
        if(page !== 'https://beta.bestpay.com.ve/' || page !== 'https://beta.bestpay.com.ve' || page !== 'http://localhost:3000/' || page !== 'http://localhost:3000') {

            let top = window.scrollY;
            if(top > 0) {

                $('nav').addClass('gray-gradient');
                $('nav').removeClass('nav-white');

            } else {

                $('nav').removeClass('gray-gradient');
                $('nav').addClass('nav-white');

            }

        } else {

            $('nav').addClass('gray-gradient');

        }

    })

    const change_nav = () => {

        let page = window.location.href;
        if(page !== 'https://beta.bestpay.com.ve/' && page !== 'https://beta.bestpay.com.ve' && page !== 'http://localhost:3000/' && page !== 'http://localhost:3000') {

            $('nav').addClass('nav-white');
            //$('nav').addClass('gray-gradient');

        } else {

            $('nav').removeClass('nav-white');

        }

    }

    /* $(window).ready(() => {

        change_nav();

    }) */

    useEffect(() => {

        change_nav();

    }, [])

    const handleSubmit = async(e) => {

        e.preventDefault();
        await axios.get(BACK_ENDPOINT + 'tracking/index.php?order_num=' + ci)
        .then(res => {

            props.show(res.data)

        })
        .catch(err => {
        
            console.error('Error', err)
        
        })

    }

    const handleChange = async(e) => {

        setCi(e.target.value)

    }

    const closeMenu = e => {

        $('#mobileMenu').addClass('d-none')

    }

    const showMenu = e => {

        $('#mobileMenu').removeClass('d-none')

    }

    const showSearchBar = () => {

        $('#mobileSearchBar').removeClass('d-none');
        $('#close-icon-min').removeClass('d-none');
        $('#search-icon-min').addClass('d-none');

    }

    const hideSearchBar= () => {

        $('#mobileSearchBar').addClass('d-none');
        $('#close-icon-min').addClass('d-none');
        $('#search-icon-min').removeClass('d-none');

    }

    return(

        <nav className='d-flex py-2 py-lg-3 px-lg-2'>
            <div id='mobileMenu' className='vh-100 gray-gradient px-3 py-5 position-absolute d-none'>
                <div className=''>
                    <button type="button" className='border-0 bg-transparent' onClick={ closeMenu }>
                        <FontAwesomeIcon icon={ faCircleXmark } />
                    </button>
                    <ul className='list-unstyled'>
                        <li>
                            <Link to='/'>Inicio</Link>
                        </li>
                        <li>
                            <Link to='/empresa'>Empresa</Link>
                        </li>
                        <li>
                            <Link to='/productos'>Productos</Link>
                        </li>
                        <li>
                            <Link to='/recaudos'>Recaudos</Link>
                        </li>
                        <li>
                            <Link to='/preguntas-frecuentes'>FAQ</Link>
                        </li>
                        <li>
                            <Link to='/contactos'>Contacto</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='col-4 col-md-2'>
                <Link className='navbar-brand' to='/'>
                    <img src={ Color } id='color-logo' />
                </Link>
            </div>
            <div className='col-md-7 d-flex align-items-center routes-div'>
                <Link to='/'>
                    Inicio
                </Link>
                <Link to='/empresa'>
                    Empresa
                </Link>
                <Link to='/productos'>
                    Productos
                </Link>
                <Link to='/recaudos'>
                    Recaudos
                </Link>
                <Link to='/preguntas-frecuentes'>
                    FAQ
                </Link>
                <Link to='/contactos'>
                    Contacto
                </Link>
            </div>
            <div className='d-none col-2 justify-content-center align-items-center menu-button'>
                <button className='bg-transparent border-0 text-white' style={{ 'fontSize': '1.5rem' }} onClick={ showMenu }>
                    <FontAwesomeIcon icon={ faBars } />
                </button>
            </div>
            <div className='col-4 col-md-3 d-flex align-items-center justify-content-center me-3 search-div'>
                <form className='form-search position-relative' onSubmit={ handleSubmit }>
                    <div>
                        <input type="text" placeholder="Tracking..." aria-label="Tracking..." className='bg-transparent border-red border-45 px-2' id='tracking-bar' onChange={ handleChange } maxLength={ 5 } />
                        <button type='button' className='search-icon position-absolute' onClick={ handleSubmit }>
                            <FontAwesomeIcon icon={ faSearch } className='text-white' />
                        </button>
                    </div>
                </form>
            </div>
            <div className='col-4 col-md-3 d-none align-items-center justify-content-end pe-4 search-min'>
                <button type='button' className='bg-transparent border-0 text-white' style={{ 'fontSize': '1.5rem' }} onClick={ showSearchBar }>
                    <FontAwesomeIcon id='search-icon-min' icon={ faSearch } className='text-white' />
                </button>
                <button ype='button' className='bg-transparent border-0 text-white' style={{ 'fontSize': '1.5rem' }} onClick={ hideSearchBar }>
                    <FontAwesomeIcon id='close-icon-min' className='text-white d-none' icon={ faX } />
                </button>
            </div>
            <div id='mobileSearchBar' className='position-absolute gray-gradient py-2 w-100 d-none'>
                <form className='form-search-mobile position-relative text-center' onSubmit={ handleSubmit }>
                    <div>
                        <input type="text" placeholder="Tracking..." aria-label="Tracking..." className='bg-transparent border-red border-2 border-45 px-2 text-dark' id='tracking-bar' onChange={ handleChange } maxLength={ 5 } />
                        <button type='button' className='search-icon position-absolute text-dark rounded border-1' onClick={ handleSubmit }>
                            <FontAwesomeIcon icon={ faSearch } className='' />
                        </button>
                    </div>
                </form>
            </div>
        </nav>

    )

}
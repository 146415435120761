import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ICONS
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

// IMAGES
import Girl from '../assets/media/base/recurs/pos-girl.webp';

// LAYOUTS
import RecursContact from "../layouts/RecursContact";

export default function Recurs(props) {

    useEffect(() => {

        // SCROLL TOP
        window.scroll(0, 10);

    }, [])

    return(

        <div>
            <div className="pt-5 mt-5">
                <h1 className="text-center text-blue">RECAUDOS</h1><br/>
                <div className="mx-auto text-center text-blue">
                    Contáctanos por WhatsApp para apoyarte en la gestión ¡Obtén un punto de venta hoy!
                </div>
                <div className="d-flex flex-wrap col-12 col-lg-6 mx-auto justify-content-around mt-5">
                    <a type='button' className="text-decoration-none border-red border-45 border-2 p-2 d-flex flex-wrap align-items-center" href='https://wa.me/584142238849' target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={ faWhatsapp } className="pe-2" style={{ 'fontSize': '18px' }} />
                        0414-2238849
                    </a>
                    <a type='button' className="text-decoration-none border-red border-45 border-2 p-2 d-flex flex-wrap align-items-center" href='https://wa.me/584142238847' target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={ faWhatsapp } className="pe-2" style={{ 'fontSize': '18px' }} />
                        0414-2238847
                    </a>
                </div>
                <div className="col-12 col-lg-10 mx-auto d-flex flex-wrap flex-xl-row flex-column-reverse mt-5 py-5 align-items-center">
                    <div className="col-12 col-md-6 text-center mx-auto mt-4 mt-lg-0">
                        <img src={ Girl } alt='Girl with a POS device' style={{ 'width': '70%' }} loading="lazy" />
                    </div>
                    <div className="col-12 col-xl-6 px-4 text-blue">
                        <h2 className="text-center">
                            OBTÉN UN PUNTO DE VENTA BESTPAY
                        </h2>
                        <div className="text-center mt-3">    
                            <strong>
                                Para obtener uno de nuestros equipos debes cumplir con los siguientes requerimientos:
                            </strong>
                        </div>
                        <ol className="list-group-numbered mt-3">
                            <li className="list-group-item">
                                Planilla de solicitud de equipos BestPay completamente llena.
                            </li>
                            <li className="list-group-item">
                                Copia del documento constitutivo de la empresa, estutos y sus modificaciones, debidamente registrados (vigentes).
                            </li>
                            <li className="list-group-item">
                                Copia del Acta de Asamblea con la designación de la Junta Directiva en funciones (vigente).
                            </li>
                            <li className="list-group-item">
                                Copia del Registro de Información Fiscal (RIF) de la empresa y de sus representantes legales (vigentes).
                            </li>
                            <li className="list-group-item">
                                Copia legible de la Cédula de Identidad y/o Pasaporte de los representantes legales (vigente).
                            </li>
                            <li className="list-group-item">
                                Certificación bancaria de la cuenta asociada al código de afilación a configurar.
                            </li>
                            <li className="list-group-item">
                                Soporte de pago.
                            </li>
                        </ol>
                    </div>
                </div>
                <RecursContact cities={ props.cities } products={ props.products } />
            </div>
        </div>

    )

}